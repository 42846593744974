import { Injectable } from '@angular/core';
import { IFile } from '../interfaces/file/file.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from '../../../core/services/global.service';
import { FileRest } from '../classes/rest/file.rest';
import { ICategoryInfo } from '../interfaces/category/category.interface';
import { ITreeDatasource } from '../../../components/treeview/tree.interface';
import { TreeService } from '../../../components/treeview/service/tree.service';
import { SlotSimulatorComponent } from '../../slots/components/simulator/simulator.component';

export interface ICategoryData {
  id: number;
  idPath: string;
  docType: string;
  namePath: string;
}
@Injectable({ providedIn: 'root' })
export class CategoryService {
  messageValue: string = '';

  selectedTree!: ITreeDatasource;
  treeService!: TreeService<ITreeDatasource>;
  currentSimulator!: SlotSimulatorComponent;

  files: IFile[] = [];
  categories: Array<ICategoryInfo> = [];
  categoryData: ICategoryData = {
    //object는 REF를 받으니까
    id: 0,
    idPath: '',
    docType: 'default',
    namePath: '카테고리를 선택해주세요.',
  };

  select(data: ITreeDatasource, treeService: TreeService<ITreeDatasource>) {
    this.selectedTree = data;
    this.treeService = treeService;
  }

  send() {
    this.create();
  }

  create() {
    if (!this.selectedTree) return;

    const id = this.getNextId();
    const childData = {
      id: id,
      name: this.messageValue,
      rootId: this.selectedTree.id,
      depth: this.selectedTree.depth + 1,
      steps: 0,
      type: 'local',
      children: [],
    };

    const found = this.treeService.findMe(this.selectedTree.id);
    found!.children.push(id); //자식에 받아온 데이터 추가해주기

    const { steps, children, ...rest } = childData;
    this.treeService.datasource.push({ ...rest, children: children }); //이거 대신 그냥 get 해도 되긴 하는데...!
    this.setCategories();
  }

  rename(name: string) {
    const category = this.categories.find((d) => d.id === this.selectedTree.id);
    if (category) {
      category.name = name;
    }

    const me = this.treeService.findMe(this.selectedTree.id);
    me!.name = name;
    this.setCategories();
  }

  load() {
    this.categories = this.loadCategories();
    return this.categories;
  }

  getNextId() {
    const allIds = this.categories.flatMap((category: any) => [
      category.id,
      ...category.children,
    ]);
    return Math.max(...allIds) + 1;
  }

  setCategories() {
    this.categories = this.treeService.datasource as any;
    localStorage.setItem('categories', JSON.stringify(this.categories)); 
    this.currentSimulator?.reload();
  }

  loadCategories() {
    const categories = localStorage.getItem('categories');
  
    if (categories == null) {
      this.categories = [
        {
          id: 1,
          name: 'create a story',
          rootId: 1,
          depth: 0,
          type: 'local',
          children: [],
        },
      ];
      // 문자열로 변환하여 로컬 스토리지에 저장
      localStorage.setItem('categories', JSON.stringify(this.categories)); 
    }
  
    return JSON.parse(localStorage.getItem('categories') as string);
  }
}