import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss'
})
export class IconButtonComponent extends DataAbstract {

  public name: string = '';
  public x: number = 0;
  public y: number = 0;

  // @HostBinding('style') style = 'position: absolute;right: 10px;margin-top: -110px;';
  @Output() click = new EventEmitter<any>();
  
  public override dataInit(): void {
    this.name = this.data.name;
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }
  
  public onClick(event: MouseEvent) {
    this.click.emit();
  }
  
}
