import { Injectable } from "@angular/core";
import { environment } from "../../../../src/environments/environment";
import { Observable } from "rxjs";
import { Socket } from "ngx-socket-io";
import { IUploadSteps } from "../interfaces/file/file.dto";

export class FileSocket{
    private socket: Socket | null = null;
    private id: string = '';

    constructor(){}

    connect(){
        this.socket = new Socket({url: `${environment.socket_url}/file`, options: {}});
        return this.onConnect();
    }

    disconnect(){
        if(!this.socket){
            alert('연결된 소켓이 없습니다.');
            return;
        }
        this.socket.disconnect();
        this.socket = null;
    }

    getSocket(): Socket | null{
        return this.socket;
    }

    onUploadSteps(){
        return new Observable<IUploadSteps>((observer) => {
            if(!this.socket) return;
            this.socket.on('uploadSteps', (data:any) => {
                observer.next(data);
            })
        });
    }

    onConnect(){
        return new Observable<any>((observer) => {
            if(!this.socket) {
                alert('소켓이 없습니다.');
                return;
            }
            this.socket
            this.socket.once('connect', () => {
                this.id = this.socket!.ioSocket.id;                
                observer.next(this.id);
            });
        })
    }
}