import { Component } from '@angular/core';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-answer-balloon-html',
  templateUrl: './answer-balloon-html.component.html',
  styleUrl: './answer-balloon-html.component.scss',
})
export class AnswerBalloonHTMLComponent extends DataAbstract {

  public answer!: string;

  public override dataInit(): void {
    this.answer = this.data;
  }

}
