import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BASE_URL } from '../../../../core/services/http.service';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrl: './file-dialog.component.scss'
})
export class FileDialogComponent implements OnInit {

  @ViewChild('preview', { read: ElementRef, static: true })
  public preview!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string
    }
  ) {}

  ngOnInit() {
    //this.showFile(this.data.name);
  }

  public showFile(file: any) {
    const previewDiv = this.preview.nativeElement;
    const pdfUrl = `${BASE_URL}/fileManage/show/${file}`;

    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@4.0.269/build/pdf.worker.mjs';
    pdfjsLib.getDocument(pdfUrl).promise.then((pdf: any) => {
      const totalPageCount = pdf.numPages;
      for (let pageNumber = 1; pageNumber <= totalPageCount; pageNumber++) {
        pdf.getPage(pageNumber).then((page: any) => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = page.getViewport({ scale: 1 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext).promise.then(() => {
            previewDiv.appendChild(canvas);
          });
        });
      }
    })
  }

}
