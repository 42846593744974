import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { BehaviorLogic } from "../abstract/behavior-logic.chatflow.abstract";

export class LogicLearn extends BehaviorLogic {

  constructor(public override liskovChatflow: LiskovChatflowModel){
    super(liskovChatflow);
  }

  override prepare(data?: data){
      this.liskovChatflow.service.index = 0;
      this.create(data);
  }

  override create(data?: data): void {
      this.events(this.liskovChatflow.service.index, data);
  }
}