import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../core/services/global.service';
import { ICategoryInfo } from '../../../../pages/category-manage/interfaces/category/category.interface';
import { TreeviewComponent } from '../../../../components/treeview/treeview/treeview.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CategoryCode } from '../../../../pages/category-manage/const/catgegory-root-code.const';
import { CategoryShared } from '../../../../pages/category-manage/classes/rest/category.shared';



@Component({
  selector: 'app-mrc-dialog',
  templateUrl: './mrc-dialog.component.html',
  styleUrl: './mrc-dialog.component.scss'
})
export class MrcDialogComponent implements OnInit {
  commonCategories: Array<ICategoryInfo> = [];
  personalCategories: Array<ICategoryInfo> = [];
  categoryCode = new CategoryCode();
  categories: Array<ICategoryInfo> = [];
  categoryShared = new CategoryShared(this.globalService);

  constructor(public dialogRef: MatDialogRef<MrcDialogComponent>, public globalService: GlobalService) {}

  ngOnInit(): void {
    this.categoryShared.getCategories({ type: this.categoryCode.personal, id: this.globalService.currentUser.userData.id }).subscribe(d =>{
        this.categories = d;
    })
  }

  onYesClick() {
    if (this.globalService.vectordbService.currentCategory == null) {
      this.globalService.httpService.snackBar('카테고리를 선택해 주세요.');
      return;
    }
    this.dialogRef.close(this.globalService.vectordbService.selectedCategories);
  }

  onNoClick(){
    this.dialogRef.close(null);
  }

  getCheckedCategory(){
    return this.globalService.vectordbService.ids;
  }

  onTreeClicked(type: string, treeId: number, treeView: TreeviewComponent<ICategoryInfo>){
    console.log('clicked');
    const path = treeView.treeService.findPath(treeId);
    const me = treeView.treeService.findMe(treeId);
    if(!me){
      //console.log('me null');
      return;
    }
    this.globalService.vectordbService.currentCategory = this.globalService.vectordbService.add(type, treeId, path, me);
    //TODO vectordbService의 값을 바로 가져오는 편이 나을 듯 합니다! - 연정
    this.globalService.dynamicLayoutService.buttonName = this.globalService.vectordbService.currentCategory?.myData?.name ?? '카테고리 선택';
    console.log('selected', this.globalService.vectordbService.selectedCategories);
  }

  //현재 선택한 카테고리 정보를 담아놓는다.
  onTabChange(event: MatTabChangeEvent){
    console.log('event', event, this.globalService.vectordbService.selectedCategories);
    this.categoryShared.getCategories({type: this.categoryCode.codes[event.index].key, id: this.globalService.currentUser.userData.id}).subscribe(d =>{
        this.categories = d;
    });
    const selected = this.globalService.vectordbService.selectedCategories[this.categoryCode.codes[event.index].key];
    if(!selected) return;//선택 안된 상태

    this.globalService.vectordbService.currentCategory = selected;
    this.globalService.dynamicLayoutService.buttonName = this.globalService.vectordbService.currentCategory?.myData?.name ?? '카테고리 선택';
  }
}
