import { BehaviorSubject } from "rxjs";

export interface CustomSubject<T> extends BehaviorSubject<T>{
    isLoggedIn(): boolean;
}

export class TestSubject<T> extends BehaviorSubject<T>{
    constructor(initialValue: T) {
        super(initialValue);
    }

    isLoggedIn(): boolean {
        console.log('isLoggedIn', this.value);
        
        return Object.getOwnPropertyNames(this.value).length === 0 ? false : true;  // 유효성 검사 추가
    }
}

export function checkStatus(prototype: any, name: string){
    Object.defineProperty(prototype, name, {
        configurable: true,
        enumerable: true,
        get() {
            return this[`_${name}`];
        },
        set(value: any) {
            console.log('Setting value:', value.value);
            this[`_${name}`] = new TestSubject(value.value);
        }
    });
}