import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { DialogService, EventParam } from './dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrl: './dialog-layout.component.scss',
})
export class DialogLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('container', {read: ViewContainerRef, static: true}) container!: ViewContainerRef;
  eventSubscription!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogService: DialogService
  ){ 
  }

  ngOnInit(): void {
    this.initDialog();
    this.listen();

  }

  initDialog(){
    const header = this.container.createComponent(HeaderComponent);
    const headerData = this.data.header;
    header.instance.title = this.data.title;
    // header.instance.event.subscribe(() => this.close());
    
    const body = this.container.createComponent(BodyComponent);
    const bodyData = this.data.body;

    const footer = this.container.createComponent(FooterComponent);
    const footerData = this.data.footer;
  }

  listen(){
    this.eventSubscription = this.dialogService.eventListener$.subscribe({
      next: (event:EventParam) => {
        console.log('이벤트, ', event);
        (this.dialogService as any)[event.event](event.param);
      }
    })
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

}
