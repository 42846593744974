
<button 
  mat-icon-button
  (click)="onClick($event)"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  style="position:absolute;"
>
  <mat-icon style="color: #3584ce">{{ socketService.isConnected ? 'stop_circle' : name }}</mat-icon>
</button>

<!-- TODO very sadly i dont want to use this code but.. i will refactor this code--> 