import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent extends DataAbstract {

  @ViewChild('inputText', { read: ElementRef, static: true }) inputText!: ElementRef;

  public label!: string;
  public disabled!: boolean;
  public placeholder!: string;
  public width!: string;
  public height!: string;
  public x!: number;
  public y!: number;

  @Output() enter = new EventEmitter<any>();

  

  public override dataInit(): void {
    this.label = this.data.label;
    this.disabled = this.data.disabled;
    this.placeholder = this.data.placeholder;
    this.width = this.data.size.width;
    this.height = this.data.size.height;
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }

  public onKeyDownEnter(event: any, value: string) {
    if (event.isComposing) return;
    if (!value) return;
    event.preventDefault();

    this.enter.emit({ value });
  }

}
