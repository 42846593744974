import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from "../../../../src/environments/environment";
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class GeminiService {
  private apiKey = environment.geminiKey;// 환경 변수에서 API Key 가져오기

  private apiUrl =
    'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';


  public isUse = true;
  public isPlay = false;

  constructor(private http: HttpClient) {
  }

  generateContent(prompt: string): Observable<string> {
    const url = `${this.apiUrl}?key=${this.decrypt(this.apiKey)}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      contents: [
        {
          parts: [
            {
              text: prompt,
            },
          ],
        },
      ],
    };

    return this.http.post<any>(url, body, { headers }).pipe(
      map((response) => {
        if (response && response.candidates && response.candidates.length > 0) {
          return response.candidates[0].content; // 생성된 텍스트 추출
        } else {
          throw new Error('No content generated');
        }
      })
    );
  }

  private encrypt(value: string): string {
    const ciphertext = CryptoJS.AES.encrypt(value, environment.base_url);
    return ciphertext.toString();
  }

  private decrypt(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, environment.base_url);
    return bytes.toString(CryptoJS.enc.Utf8);
  }


  createAiData(prompt: any): Observable<any> {

    if (this.isUse) {

      return this.generateContent(prompt).pipe(
        switchMap((text) => {
          return of(text); 
        }),
        catchError(() => { // 팩토리 함수 사용
          const error = new Error('Error occurred during content generation'); 
          console.error(error);
          return throwError(() => error); 
        })
      );
    } else {
      return of('AI 사용 안함'); 
    }
  }

  parseJsonFromDelimitedString(
    inputString: string, 
    startPattern: RegExp, 
    endPattern: RegExp, 
    isArray: boolean
  ): any {
    const startIndex = inputString.search(startPattern);
    const endIndex = inputString.search(endPattern);
  
    if (startIndex === -1 || endIndex === -1) {
      throw new Error("JSON 데이터를 찾을 수 없습니다.");
    }
  
    const jsonString = (isArray ? "[" : "{") 
      + inputString.substring(startIndex + startPattern.source.length, endIndex)
      + (isArray ? "]" : "}");
  
    try {
      const parsedData = JSON.parse(jsonString);
      return parsedData;
    } catch (error) {
      console.error(error); // 에러 로깅은 console.error를 사용하는 것이 좋습니다.
    }
  }
}
