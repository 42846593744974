import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SlotDefaultComponent } from '../../components/default/default.component';
import { State } from '../../service/docking.service';
import { GlobalService } from '../../../../core/services/global.service';


@Component({
  selector: 'app-slot-prompt-bottom-sheet',
  templateUrl: './slot-prompt-bottom-sheet.component.html',
  styleUrl: './slot-prompt-bottom-sheet.component.scss'
})
export class BottomSlotPromptSheetComponent {

  @ViewChild('promptText', { read: ElementRef, static: true }) promptText!: ElementRef;

  public prompt!: string;

  public selectedValue: string = '';

  stateCtrl = new FormControl('');
  filteredStates: Observable<State[]>;

  constructor(
    public globalService: GlobalService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSlotPromptSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.prompt = data.prompt;

    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterStates(state) : this.globalService.aiService.promptStates.slice())),
    );
  }

  private _filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();

    return this.globalService.aiService.promptStates.filter(state => state.name.toLowerCase().includes(filterValue));
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }

  action() {
    this.globalService.aiService.setPrompt(this.selectedValue);
    this.closeBottomSheet();
  }

  // public clearSlot() {
  //   this.globalService.dockingService.slotData = [];
  //   localStorage.setItem('slot', JSON.stringify(this.globalService.dockingService.slotData));
  //   this.globalService.httpService.snackBar('초기화 되었습니다.');
  // }

}
