import { Component, OnInit } from '@angular/core';
import { StoreAbstract } from '../../Modules/core/classes/store.abstract';
import { SketchflowData } from '../../Modules/pages/sketchflow/classes/data/sketchflow.data';
import { Router } from '@angular/router';

declare global {

  type svg = any;
  type data = any;
  type event = any;
  type stringNumber = string | number;


  const pdfjsLib:any;
  const BABYLON:any;

  interface Array<T> {
    last(): any;
    first(): any;
    isEmpty(): boolean;
    stringify() : any;

  }

}

Array.prototype.stringify = function () {
  return JSON.stringify(this);
}

Array.prototype.last = function () {
  return this[this.length - 1];
}

Array.prototype.first = function () {
  return this[0];
}

Array.prototype.isEmpty = function () {
  if (this.length === 0) return true;
  else return false;
}

export function NullCheckParameters(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    for (const arg of args) {
      if (arg === null || arg === undefined || arg === false) {
        return;
      }
    }
    return originalMethod.apply(this, args);
  };

  return descriptor;
}

export function ConditionalExecution(target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    for (const arg of args) {
      if (!arg.apply(this)) {
        return;
      }
    }
    return originalMethod.apply(this, args);

  };
  return descriptor;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends StoreAbstract implements OnInit {

  public triggerType: string = 'mainDrawer';
  private sketchflowData = new SketchflowData();
  constructor(private router: Router) {
    super()
  }
  
  ngOnInit() {
    this.layoutService.getBodyHeight();
    this.redirectUrl();

    // main default -> this.logicService.setLogic('main');
    // chatbot default -> this.logicService.setLogic('chatbot');
    // this.logicSketchflowService.setValue('chatbot', this.sketchflowData.chatbot);
    // this.logicSketchflowService.setValue('login', this.sketchflowData.login);
  }

  // private createBackground() {
  //   const data = {
  //     type: 'background',
  //     component: SvgBackground,
  //     position: { x: 0, y: 0 },
  //     size: { width: 100, height: 500 },
  //   };
  //  this.bindComponent(data.type, this, data, this.mainCanvas.backgroundGroup);
  // }

  redirectUrl() {
    const previousUrl = localStorage.getItem('previousUrl');
    if (previousUrl) {
      const url = new URL(previousUrl);
      const path = url.pathname; // 도메인 제외한 경로
      this.router.navigateByUrl(path);
      console.log('previousUrl', path);
      localStorage.removeItem('previousUrl');
    }
  }

  async persona_api() {
      const message = '너는 누구니 ?'; // 메시지 입력 필드에서 값 가져오기
    
      try {
        const response = await fetch("http://183.98.131.168:8889/generate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message }),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const reader = (response.body ?? new ReadableStream()).getReader();
        const decoder = new TextDecoder("utf-8");
        let output = "";
    
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
    
          output += decoder.decode(value);
          console.log(output); // 출력 영역에 업데이트
        }
      } catch (error) {
        console.error("Error:", error);
        // 에러 처리 (사용자에게 알림 등)
      }
    }

}
