import { Component, Input } from '@angular/core';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() title!: string;

  constructor(
    private dialogService: DialogService
  ){}

  close(){
    this.dialogService.eventListener$.next({ event: 'close', param: undefined });
  }
}
