import { KeyValue } from "@angular/common";
import { LayoutChatPage } from "../layouts/chatpage.chatflow";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { IChatflow } from "../interface/chatflow.interface";
import { LayoutChatHistoryPage } from "../layouts/chathistorypage.chatflow";
import { Router } from "@angular/router";

export class LayoutManager {
  public layoutComponents: KeyValue<string, IChatflow>[] = [];

  constructor(public router: Router, public liskovChatflow: LiskovChatflowModel) {}

  init(layouts: any) {
    this.layoutComponents.push({key: 'chatpage', value: new LayoutChatPage(this.liskovChatflow)});
    this.layoutComponents.push({key: 'chathistorypage', value: new LayoutChatHistoryPage(this.router, this.liskovChatflow)});

    this.setData(layouts);
  }

  private setData(layouts: any) {
    layouts.scenarios.forEach((d: any) => {
      this.find(d.key)?.init(d); // abstract 클래스에서 데이터 init
    });
  }

  find<T extends IChatflow>(key: string): T {
    return this.layoutComponents.find(d => d.key === key)?.value as T;
  }
}