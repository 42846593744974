import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError } from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { ChatHistoryHttp } from '../http/chat-history.http';
import { ConfirmDialogComponent } from '../../shared/components/molecules/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface CombineModel {
  url: string;
  data: any;
  header?: any;
}

export interface IParams {
  [param: string]: string | string[]
}

export const BASE_URL = environment.base_url;

@Injectable({ providedIn: 'root' })
export class HttpService {

  constructor(
    public http: HttpClient, 
    public dialog: MatDialog,
    private matSnack: MatSnackBar
  ) { }

  public Delete(endPoint: string): Observable<any> {
    return this.http.delete(`${BASE_URL}${endPoint}`)
      .pipe(catchError(this.handleError("Delete")));
  }
  
  public DeleteWithBody(endPoint: string, data: any): Observable<any> {
    return this.http.delete(`${BASE_URL}${endPoint}`, {
      body: data
    })
      .pipe(catchError(this.handleError("DeleteWithBody")));
  }

  public Post(endPoint: string, data: any, header?: any): Observable<any> {
    return this.http.post(`${BASE_URL}${endPoint}`, data, header)
      .pipe(catchError(this.handleError("Post", data)));
  }

  public Put(endPoint: string, data: any, header?: any): Observable<any> {
    return this.http.put(`${BASE_URL}${endPoint}`, data, header)
      .pipe(catchError(this.handleError("Put", data)));
  }

  public Patch(endPoint: string, data: any, header?: any) {
    return this.http.patch(`${BASE_URL}${endPoint}`, data, header)
      .pipe(catchError(this.handleError("Patch", data)));
  }

  public Get(endPoint: string, params?: IParams): Observable<any> {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
          (params[key] as string[]).forEach(value => {
            httpParams = httpParams.append(key, value);
          });
        }
        else {
          httpParams = httpParams.set(key, (params[key] as string));
        }
      });
    }

    return this.http.get(`${BASE_URL}${endPoint}`, { params: httpParams })
      .pipe(catchError(this.handleError("Get")));
  }

  public GetJson(url: string): Observable<any> {
    return this.http.get(url)
      .pipe(catchError(this.handleError("Get")));
  }

  public GetBlob(endPoint: string){
    return this.http.get(`${BASE_URL}${endPoint}`,  { responseType: 'blob', observe: 'response' });
  }

  public CombineLatest(data: CombineModel[]) {
    const sources: Observable<any>[] = [];

    data.forEach((d: CombineModel) => {
      const observer = this.http.post(d.url, d.data, d.header);
      sources.push(observer);
    });
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): any => {
      this.snackBar(error.message, "ERROR");
    };
  }

  public snackBar(text: string, undo: string = "", duration: number = 2000) {
    this.matSnack.open(text, undo, {
      duration: duration,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    });
  }

  openDeleteConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { 
        title: '정말로 초기화 하시겠습니까?',
        content: '초기화하시면 다시 복구시킬 수 없습니다.',
        btnYes: '삭제', 
        btnNo: '취소'
       }
    });
    return dialogRef;
  }
}
