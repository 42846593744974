import { Injectable } from "@angular/core";
import { IMenu } from "../../menu-item/menu/menu.interface";
import { ITreeDatasource } from "../tree.interface";
import { TreeviewStore } from "../tree.store";

@Injectable()
export class TreeService<D extends ITreeDatasource>{
    menuClass!: IMenu;//나중에 이것도 바깥으로 뺄 수 있다!

    dragDisabled!: boolean;
    checkDisabled!: boolean;
    private _datasource: Array<D> = [];

    constructor(
        private readonly treeviewStore: TreeviewStore,
    ){
        
    }

    get datasource(){
        return this._datasource;
    }
    set datasource(datasource: Array<D>){
        this._datasource = datasource;
    }

    findMe(id: number): D | null{ //undefined보다는 null로 하자! null체크를 하자.
        return this.datasource.find(d => d.id === id) ?? null;
    }

    findParent(id: number){
        return this.datasource.find(parent => parent.children.find(child=> child === id));
    }

    findParents(id: number, arr: Array<number>){
        const parent = this.datasource.find(parent => parent.children.find(child=> child === id));
        if (!parent) return;
        this.findParents(parent.id, arr);
        arr.push(parent.id);//부모 찾기가 끝나서 가장 높은 부모를 찾고 나면 arr에 푸시를 한다.
    }

    findPath(id: number){
        const path: Array<number> = [];
        this.findParents(id, path);
        path.push(id);
        //console.log('id', id, 'path', path);
        return path.join('/');
    }

    select(id: number) {
        this.treeviewStore.treeClick(id);
    }
}