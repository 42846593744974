import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class LayoutService {

  public isHistoryLoading = false;
  public isChatBoxLoading = false;

  public isButtonHover: boolean = false; 
  public isOpen: boolean = true;
  public leftWidh!: number;
  public rightWidth!: number;
  public bodyHeight!: string;
  public bodyWidth!: string;

  public isShow: boolean = false;
  public isDragDisable: boolean = false;

  public svgHeaderHeight: number = 93;

  public getBodyHeight() : string {
    this.bodyHeight = `${window.innerHeight - 64}px`; // header의 높이
    return this.bodyHeight;
  }

  //연정 추가. splitter는 꽉차게. 양쪽은 위에 마진을 주게. 추후 수정 가능!
  public getCategorySplitterHeight() : number {
    return window.innerHeight - 64;//tap 48
  }

  public getCategorySideHeight() : number {
    return window.innerHeight - 64 -25;//margin-top 25
  }

  public getChatContainerHeight(): string {
    this.bodyHeight = `${window.innerHeight - 265}px`; // chat container의 높이
    return this.bodyHeight;
  }

  public getChatflowTabHeight(): string {
    this.bodyHeight = `${window.innerHeight - 112}px`; // chatflow container의 높이
    return this.bodyHeight;
  }  

  public getChatflowContainerHeight(): string {
    this.bodyHeight = `${window.innerHeight - 190}px`; // chatflow container의 높이
    return this.bodyHeight;
  }
  
  public getInnerWidth(): string {
    this.bodyWidth = `${window.innerWidth - 20}px`;
    return this.bodyWidth;
  }

  public convertStringToNumber(width: string): number {
    const match = width.match(/\d+/);
    return match ? parseInt(match.first(), 10) : 0; 
  }

  public convertStarWidth(width: string, colSpan: number): string { 
    //const numericTotalWidth = parseInt(totalWidth.replace('px', ''), 10);
    let starWidth;
    // if (width === '*') {
    //   starWidth = numericTotalWidth / colSpan;
    //   return `${starWidth}px`;
    // }
    const numericWidth =  parseInt(width.replace('px', ''), 10);
    starWidth = numericWidth / colSpan;
    return `${starWidth}px`;
  }

  public calculateWidth(width: string, x: number): string {
    if (width === '*') return '*';
    const numericWidth = parseInt(width.replace('px', ''), 10);
    return `${numericWidth - x}px`;
  }

  public calculateHeight(height: string, x: number): string {
    const numericHeight = parseInt(height.replace('px', ''), 10);
    return `${numericHeight - x}px`;
  }

}