import { inject } from "@angular/core";
import { INode } from "../../pages/chatflow/interfaces/chatflow.interface";
import { selectCategory } from "../store/category/category.selector";
import { selectDeliver } from "../store/deliver/deliver.selector";
import { selectExpansionPanelBox } from "../store/expansion-panel-box/expansion-panel-box.selector";
import { selectorLayout } from "../store/layout/layout.selector";
import { selectorNode } from "../store/node/node.selector";
import { selectTrigger } from "../store/trigger/trigger.selector";
import { vectordb } from "../store/vectordb/vectordb.selector";
import { HttpService } from "../services/http.service";
import { Store } from "@ngrx/store";
import { CurrentUserService } from "../services/current-user.service";
import { DalService } from "../services/dal.service";
import { FactoryService } from "../services/factory.service";
import { HistoryService } from "../services/history.service";
import { LayoutService } from "../services/layout.service";
import { LogicService } from "../services/logic.service";
import { SocketService } from "../services/socket.service";
import { SolutionService } from "../services/solution.service";
import { StoreService } from "../services/store.service";
import { DynamicLayoutService } from "../services/dynamic-layout.service";
import { TrackingService } from "../services/tracking.service";
import { MembershipService } from "../../pages/membership/membership.service";
import { GlobalService } from './../services/global.service';
import { CategorySelectService } from "../services/category-select.service";
import { LogicSketchflowService } from "../services/logic-sketchflow.service";

export abstract class StoreAbstract {

  protected readonly globalService = inject(GlobalService);
  protected readonly httpService = inject(HttpService);
  protected readonly socketService = inject(SocketService);
  protected readonly factoryService = inject(FactoryService);
  protected readonly solutionService = inject(SolutionService);
  protected readonly layoutService = inject(LayoutService);
  protected readonly storeService = inject(StoreService);
  protected readonly logicService = inject(LogicService);
  protected readonly logicSketchflowService = inject(LogicSketchflowService);
  protected readonly store = inject(Store);
  protected readonly vectordbService = inject(CategorySelectService);
  protected readonly dalService = inject(DalService);
  protected readonly currentUser = inject(CurrentUserService);
  protected readonly membershipService = inject(MembershipService);
  protected readonly dynamicLayoutService = inject(DynamicLayoutService);
  protected readonly historyService = inject(HistoryService);
  protected readonly trackingService = inject(TrackingService);

  expansionPanelBox$ = this.store.select(selectExpansionPanelBox);
  trigger$ = this.store.select(selectTrigger);
  deliver$ = this.store.select(selectDeliver);
  category$ = this.store.select(selectCategory);
  resize$ = this.store.select(selectorLayout);
  node$ = this.store.select(selectorNode);
  vectordb$ = this.store.select(vectordb);

  protected addNode(data: INode) {
    this.storeService.addNode(data);
  }

  protected updateNode(data: INode) {
    this.storeService.updateNode(data);
  }

  protected clearNode() {
    this.storeService.clearNode();
  }

  protected trigger(triggerType: string){
    this.storeService.trigger(triggerType);
  }

  protected deliver(deliverType: string, data: any){
    this.storeService.deliver(deliverType, data);
  }

  protected sortBox(data: any) {
    this.storeService.sortBox(data);
  }

  protected resize(data: any) {
    this.storeService.resize(data);
  }

  protected OnResizeRedux() {
    this.resize$.subscribe(d => {
      this.layoutService.leftWidh = d.leftWidth;
      this.layoutService.rightWidth = d.rightWidth;
    })
  }

  protected resizeButton(event: MouseEvent) {
    this.layoutService.isOpen = !this.layoutService.isOpen;

    if (this.layoutService.isOpen) {
      this.storeService.resize({
        leftWidth: 50,
        rightWidth: 50
      });
    }
    else {
      this.storeService.resize({
        leftWidth: 0,
        rightWidth: 100
      })
    }
  }

}
