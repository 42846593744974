import { Directive, ElementRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { StoreAbstract } from "./store.abstract";

@Directive()
export abstract class DataAbstract extends StoreAbstract {
  
  @ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;
  @ViewChild('scrollBody', { read: ElementRef, static: true }) scrollBody!: ElementRef;

  private _data: any;
  public isProgress: boolean = true;

  @Input()
  public get data(): any {
    return this._data;
  }
  public set data(v: any) {
    if (v != null) {
      this._data = v;
      this.dataInit();
    }
  }

  @Output() loaded = new EventEmitter<any>();

  public dataInit() {}

}