import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MembershipStore } from "../../component-store/membership.store";
import { DialogLayoutComponent } from "../../components/dialogs/dialog-layout/dialog-layout.component";
import { take } from "rxjs";

export interface DialogTools{
    membershipStore: MembershipStore;
    matdialog: MatDialog;
}

export class DialogOptionGenerator {
    private config: DialogTools = {} as DialogTools;
    
    store(store: MembershipStore): this{
        this.config.membershipStore = store;
        return this;
    }

    dialog(dialog:MatDialog):this{
        this.config.matdialog = dialog;
        return this;
    }

    build():DialogOption {
        return new DialogOption(this.config.membershipStore, this.config.matdialog);
    }
}

export class DialogOption {
    private membershipStore: MembershipStore;
    private dialog: MatDialog;
    private dialogRef!: MatDialogRef<DialogLayoutComponent>;

    constructor(membershipStore: MembershipStore, dialog: MatDialog){
        this.membershipStore = membershipStore;
        this.dialog = dialog
    }

    private _dialogConfig: MatDialogConfig<any> = {
        width: '35%',
        minWidth: '350px',
        maxWidth: '500px',
        data: {
            title: '회원가입'
        }
    };

    set dialogConfig(config: MatDialogConfig) {
        this._dialogConfig = config;
    }

    setData(data: any) {
        this._dialogConfig.data = data;
    }

    close() {
        this.dialogRef.close();
    }

    open(): MatDialogRef<DialogLayoutComponent> {
        const dialog = this.dialog.open(DialogLayoutComponent, this.dialogConfig);
        this.dialogRef = dialog;
        return dialog;
    }

    // submit() {
    //     this.membershipStore.formGroup$.pipe(
    //         take(1)
    //     ).subscribe({
    //         next: formGroup => {
    //             console.log(formGroup);
    //         }
    //     });
    // }
}