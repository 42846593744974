import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

export interface ITreeCheck{
    clickedTreeId: number;
    isChecked: boolean
}

export interface IMoveData{//이동시 필요한 데이터
    movedParentId: number;
    movedData: {
        previousIndex: number;//기존 인덱스의 데이터가
        currentIndex: number;//currentIndex 자리로 이동했다!
    };
}

export interface IClick{
    id: number
}


export interface ITreeviewStore {
    checkEvent: ITreeCheck;
    clickEvent: IClick;
    moveEvent: IMoveData;
}

//이벤트 관리
@Injectable()
export class TreeviewStore extends ComponentStore<ITreeviewStore>{
    constructor(){
        super(
            {
                checkEvent: {
                    clickedTreeId: -1,
                    isChecked: false
                },
                clickEvent: {id: -1},
                moveEvent: {
                    movedParentId: -1,
                    movedData: {
                        previousIndex: -1,
                        currentIndex: -1,
                    }
                }
            }
            );
    }

    readonly clickedTreeId$: Observable<IClick> = this.select(state => state.clickEvent);
    readonly moveEvent$: Observable<IMoveData> = this.select(state => state.moveEvent);
    readonly checkEvent$ = this.select(state => state.checkEvent);

    readonly treeCheck = this.updater((state, data: ITreeCheck) => ({
        ...state,
        checkEvent: data,
    }));

    readonly treeClick = this.updater((state, clickedTreeId: number) => ({
        ...state,
        clickEvent: {id: clickedTreeId},//그럼 만약 내가 트리 클릭할 떄마다 clickedTree이벤트를 계속 발생시키고 싶으면 그냥 객체로 묶어버리면 되겠네?
    }));

    readonly treeMove = this.updater((state, data: IMoveData) => ({
        ...state,
        moveEvent: {
            movedParentId: data.movedParentId,
            movedData: data.movedData,
        }
    }));
}