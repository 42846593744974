import { Injectable, Type } from '@angular/core';
import { DockingService } from './docking.service';
import { SlotComponent } from '../slot/slot.component';
import { FlowViewComponent } from '../components/flow-view/flow-view.component';
import { BottomSheetComponent } from '../../chatbot/components/bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSlotSheetComponent } from '../slot/bottom-sheet/slot-bottom-sheet.component';
import { BottomSlotPromptSheetComponent } from '../slot/prompt-bottom-sheet/slot-prompt-bottom-sheet.component';
import { GlobalService } from '../../../core/services/global.service';
import { DynamicFormComponent, IKeyValue } from '../form/dynamic-form/dynamic-form.component';
import { SlotFormTextAreaComponent } from '../items/forms/slot-form-textarea/slot-form-textarea.component';
import { SvgAbstract } from '../svg/svg.abstract';
import { SlotAbstract } from '../slot.abstract';

@Injectable({ providedIn: 'root' })
export class SlotService {
  public dockingService!: DockingService;
  public slot!: SlotComponent;

  public isBodyBind = false;
  public bodyZoneInstance!: any;

  public currentBodyKey = '';
  public currentFactoryMessage = '';
  public isPossibleSlotDrag = true;
  public isSlotWork = false;
  public isBodyMatch = false;
  public isDrawLine = false;
  public flowViewComponent!: FlowViewComponent;
  public prompt: string = '';

  public dynamicForm!:DynamicFormComponent;

  public currentForm!:SlotAbstract;

  constructor(
    private _bottomSheet: MatBottomSheet,
  ) {}

  public setCurrentFormValue(value:string) {
    this.currentForm.formValue = value;
    this.currentForm.setFormData();
    console.log('setCurrentFromValue', this.currentForm.formValue);
  }

  public openDynamicForm(form: SlotAbstract, data:IKeyValue[]) {
    if(this.isSlotWork) {
      this.currentForm = form;
      this.dynamicForm.onLoadForm(data);
      this.slot.slotDrawer.toggle();
    }
  }

  public openBottomSheet() {
    this._bottomSheet.open(BottomSheetComponent, {
      data: { prompt: this.prompt },
    });
  }

  public openSlotBottomSheet() {
    this.isBodyBind = false;
    this._bottomSheet.open(BottomSlotSheetComponent, {
      data: { prompt: this.prompt },
    });
  }

  public openSlotBottomSheetBody() {
    this.isBodyBind = true;
    this._bottomSheet.open(BottomSlotSheetComponent, {
      data: { prompt: this.prompt },
    });
  }

  public openSlotPromptBottomSheetBody() {
    this.isBodyBind = true;
    this._bottomSheet.open(BottomSlotPromptSheetComponent, {
      data: { prompt: this.prompt },
    });
  }

}
