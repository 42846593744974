

<div class="swiper">
  <svg class="progress-ring" width="50" height="50">
    <circle class="progress-ring" stroke="#e2e2e2" opacity="0.5" stroke-width="5" fill="transparent" r="16" cx="25" cy="25"/>
    <circle class="progress-ring__circle" stroke="#007bff"  stroke-width="5" fill="transparent" r="16" cx="25" cy="25"/>
 </svg>

  <div class="swiper-wrapper">
    @for (item of items; track $index) {
      <div class="swiper-slide">
        <img src="./assets/images/swiper.png" alt="이미지 설명">
        <div class="text-container">{{item}}</div>
      </div>
    }
  </div>
  <div class="swiper-pagination"></div>
</div>
