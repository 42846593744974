<div class="full-bottom-sheet" style="overflow: hidden;">
  <div class="bottom-sheet-top">
    <h2 style="font-weight: 700;">Select Prompt</h2>
    <button mat-icon-button matTooltip="취소" (click)="closeBottomSheet()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <mat-label>Type</mat-label>
      <input matInput aria-label="component" [(ngModel)]="selectedValue" [matAutocomplete]="auto" [formControl]="stateCtrl">
      <mat-autocomplete #auto="matAutocomplete">
        @for (state of filteredStates | async; track state) {
        <mat-option [value]="state.name">
          <img alt="" class="example-option-img" [src]="state.flag" height="25">
          <span>{{state.name}}</span> |
          <small>{{state.type}}</small>
        </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <br>

    <button mat-raised-button color="primary" (click)="action()" matTooltip="실행">실행</button>
  </form>

  <!-- <mat-form-field>
    <mat-label>Prompt Suggestion</mat-label>
    <textarea 
      #promptText
      matInput 
      placeholder="Text"
      [value]="prompt"
      (keydown.enter)="getPrompt($event, promptText.value)"
    ></textarea>
  </mat-form-field> -->

</div>