import { Injectable } from '@angular/core';
import { ITreeDatasource } from '../../../components/treeview/tree.interface';
import { TreeService } from '../../../components/treeview/service/tree.service';
import { SlotFormTextAreaComponent } from '../items/forms/slot-form-textarea/slot-form-textarea.component';

@Injectable({ providedIn: 'root' })
export class SlotFormService {
  titleValue:string = '';
  contentValue:string = '';
  originValue:string = '';
  aiValue:string = '';
  actionValue:string = '';

  currentSource!:ITreeDatasource;
  currentTreeService!:TreeService<ITreeDatasource>;
  streamComponentList:SlotFormTextAreaComponent[] = [];

  constructor(
  ) {}
  
  select(source:ITreeDatasource, treeService:TreeService<ITreeDatasource>) {
    this.currentSource = source;
    this.currentTreeService = treeService;
    this.titleValue = source.name;
    this.contentValue = source.data?.contentValue;
    this.aiValue = source.data?.aiValue;
  }

  setContent() {
    this.currentSource.data = this.currentSource.data ?? {contentValue : '', titleValue : '', originValue : '', aiValue : ''};
    this.currentSource.data.contentValue = this.contentValue;
    this.currentSource.data.aiValue = this.aiValue;
    this.currentSource.data.originValue = this.originValue;
    // this.currentSource.data.actionValue = this.actionValue;
  }
}
