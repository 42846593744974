<div class="inner-form">
    <div class="item-left form-row">
        {{config.title}}
    </div>
    <div class="item-right">
        <mat-form-field appearance="fill" class="custom-form-field">
            <mat-label>{{config.label}}</mat-label>
            <input #passwordInput matInput [formControl]="control" [placeholder]="config.placeholder" [type]="hide ? 'password' : 'text'"
                [matTooltip]="config.tooltip">
            <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>