import { ElementRef } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function passwordValidator(confirmPasswordInput?: ElementRef): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if(!value){
            return null;
        }
        const hasUpperCase = /[A-Z]+/.test(value);
        const hasLowerCase = /[a-z]+/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test(value);
        const minLength = control.value.length >= 6;
        const maxLength = control.value.length <= 16;

        if (confirmPasswordInput) {
            confirmPasswordInput.nativeElement.value = '';
        }

        // control.parent?.get('confirmPassword')

        const passwordValid = (hasUpperCase || hasLowerCase) && hasSpecialChar && (minLength && maxLength);
        
        return passwordValid ? null : { message: '비밀번호는 8~16자 영문, 숫자, 특수문자를 포함해야 합니다.'}
    }
}