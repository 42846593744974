import { SwiperComponent } from "../../../shared/components/atoms/swiper/swiper.component";
import { AnswerBalloonHTMLComponent } from "../../../shared/components/molecules/answer-balloon-html/answer-balloon-html.component";
import { SpeakIntroComponent } from "../../../shared/components/molecules/speak-intro/speak-intro.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { IChatflowLogic } from "../interface/logic.chatflow.interface";
import { LogicChat } from "../logics/chat.chatflow";
import { Chatflow } from "./chatflow.abstract";

export abstract class ChatflowLogic extends Chatflow implements IChatflowLogic {

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }

  override async events(index: number, data?: any) {
    if (index === this.jsonData.length) {
      this.finish(this.result); // DB 저장
      return;
    }

    if (index === 0) {
      this.jsonData = data;
      this.jsonData.userValue = this.liskovChatflow.service.liskovChatflow.logicData.last();
      this.result = '';
    }

    const node = this.jsonData[index].type;
    await (this as any)[node](this.jsonData[index]);
  }

  speak(data: any) {
    /**speak노드 중에서도 타입이 나눠질 수 있다고 가정. 추후 수정 가능. 그래도 동적 컴포넌트니까 일단 최대한 바깥으로 데이터 빼봄.. - 연정 */
    if(data.node.category === 'intro'){
      this.liskovChatflow.logicManager.find('chat')?.prepare({
        message: {
          content: data.node.result[0],
          url: data.node.result[1]
        },
        component: SpeakIntroComponent
      });
      return;
    }

    this.result += `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ data.node.result[0] }</pre></div>`
    this.liskovChatflow.logicManager.find('chat')?.prepare({
        message: this.result,
        component: AnswerBalloonHTMLComponent
    });
    return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
      this.events(index);
    });
  }

  slot(data: any) {
    this.liskovChatflow.logicManager.find('chat')?.prepare({
      message: data.node.result,
      component: SwiperComponent
    });
    return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
      this.events(index); // slot 노드 데이터 DB에 어떻게 저장할 지, 더 생각해봐야함!!!
    });
  }

  override finish(data: string): void {
    (this.liskovChatflow.logicManager.find('chat') as LogicChat).clear(data).subscribe((response: any) => {});
  }

}
