import { ComponentRef } from "@angular/core";
import { ExpansionPanelComponent } from "../../../pages/chatflow/components/atom/expansion-panel/expansion-panel.component";
import { AnswerBalloonHTMLComponent } from "../../../shared/components/molecules/answer-balloon-html/answer-balloon-html.component";
import { AnswerBalloonComponent } from "../../../shared/components/molecules/answer-balloon/answer-balloon.component";
import { QuestionBalloonComponent } from "../../../shared/components/molecules/question-balloon/question-balloon.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { ChatflowLogic } from "../abstract/logic.chatflow.abstract";
import { Observable } from "rxjs";
import { DataAbstract } from "../../classes/data.abstract";

export class LogicHistory extends ChatflowLogic {

  public card!: ComponentRef<DataAbstract>;

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }

  private setDataType(type: string) {
    let result = 'system';
    switch (type) {
      case 'question':
        result = 'user';
        break;
      case 'answer':
        result = 'system';
        break;
    }
    return result;
  }

  override computed(data?: any): Observable<any> {
    return this.liskovChatflow.service.dalService.chatHistoryHttp.create({
      userIdx: this.liskovChatflow.service.currentUser.userData.userIdx,
      chatPageId: this.liskovChatflow.service.historyService.currentChatPageId,
      data: JSON.stringify({
        //id: this.liskovChatflow.service.logicService.getGUID(),
        user: this.setDataType(data.type),
        message: data.message,
        metadata: data.metadata,
        data: this.liskovChatflow.service.historyService.getCurrentDate(),
      })
    });
  }

  // history 가져오기
  override prepare(data?: data): void {
    this.liskovChatflow.service.index = 0;
    this.create(data);
  }

  override create(data?: data): void {
    if (!this.liskovChatflow.service.historyService.pageHistoryList) return;

    this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.container.clear();
    this.liskovChatflow.service.historyService.pageHistoryList.value.forEach((history: any) => {
      switch (history.user) {
        case 'system':
          this.card = this.liskovChatflow.service.factoryService.createComponent(
            AnswerBalloonHTMLComponent,
            this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.container,
            history.message
          );

          if (!history.metadata.isEmpty()) {
            history.metadata.forEach((metadata: any) => {
              this.liskovChatflow.service.factoryService.createComponent(
                ExpansionPanelComponent,
                this.card.instance.container,
                metadata
              );
            });
          }
          break;
        case 'user':
          this.card = this.liskovChatflow.service.factoryService.createComponent(
            QuestionBalloonComponent,
            this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.container,
            history.message
          );
          break;
        case 'complain':
          this.card = this.liskovChatflow.service.factoryService.createComponent(
            AnswerBalloonHTMLComponent,
            this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.container,
            history.message
          );
          break;
      }

      if(this.card == null) return;
      this.card.instance.isProgress = false;
    });
  }

}
