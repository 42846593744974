import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldData } from '../../interfaces/dynamic-form-option.interface';
import { AbstractFormFeild } from '../../components/abstracts/form-field.abstract';

@Component({
  selector: 'app-passwordfield',
  templateUrl: './passwordfield.component.html',
  styleUrl: './passwordfield.component.scss'
})
export class PasswordfieldComponent extends AbstractFormFeild implements OnInit {
  @Input() initData!: FormFieldData;
  @Output() event = new EventEmitter<FormControl>();
  @Output() viewInit = new EventEmitter<ElementRef>();
  
  hide = true;

  ngOnInit(): void {
    this.init(this.initData);
  }
}
