import { ExpansionPanelComponent } from "../../../pages/chatflow/components/atom/expansion-panel/expansion-panel.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { ChatflowLogic } from "../abstract/logic.chatflow.abstract";

interface IMetadata {
  title: string;
  metadata: string;
}

export class LogicMetadata extends ChatflowLogic {

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }
  
  override create(data?: any): void {
    //this.liskovChatflow.service.storeService.add(data);
    data.forEach((metadata: any) => {
      this.liskovChatflow.service.factoryService.createComponent(
        ExpansionPanelComponent,
        this.liskovChatflow.service.dynamicLayoutService.answerContainer.instance.container,
        metadata
      );
    });
  }
}