import { Injectable } from '@angular/core';
import { SlotItemPinLineComponent } from '../items/slot-item-pin-line/slot-item-pin-line.component';
import { IPoint } from '../svg/svg.interface';
import { FlowItemComponent } from '../components/flow-item/flow-item.component';
import { SlotStorySwieprFightingComponent } from '../items/slot-story-swiper-fighting/slot-story-swiper-fighting.component';

@Injectable({ providedIn: 'root' })
export class FlowService {
  public currentLineItem!: SlotItemPinLineComponent;
  public currentLineItemPosition: IPoint = { x: 0, y: 0 };
  public currentLineEndItem!: SlotItemPinLineComponent;
  public flowData: any[] = [];

  public LogicItemComponents: FlowItemComponent[] = [];
  public currentDataItem: any = null;
  public currentFlowIndex = 0;
  public currentLogic!: FlowItemComponent;
  public currentFighting!: SlotStorySwieprFightingComponent;

  add(data: data) {
    this.flowData.push(data);
    localStorage.setItem('flow', this.flowData.stringify());
    console.log('flow add', this.flowData);
  }

  load() {
    this.flowData = JSON.parse(localStorage.getItem('flow') as string) ?? [];
    console.log('flow load', this.flowData);
  }

  logicFlow() {
      if (this.currentLogic) {
        this.currentLogic.isSelected = false;
      }
      this.currentLogic = this.LogicItemComponents[this.currentFlowIndex];

      if(!this.currentLogic) return;
      this.currentLogic.isSelected = true;
    }
}
