import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './interfaces/user.interface';
import { BehaviorSubject, Subject, catchError, map, tap} from 'rxjs';
import { environment } from '../../../src/environments/environment';
import { CreateUserDto } from './dto/create-user.dto';
import { UpdateUserDto } from './dto/update-user.dto';
import { ResetPasswordDto } from './dto/reset-password.dto';
import { UpdatePassowdDto } from './dto/update-password.dto';
import { ValidatePasswordDto } from './dto/validate-password.dto';
import { UpdateAdminDto } from './dto/update-admin.dto';
import { RoleId } from './util/enum/role-id.enum';
import { FormConfigGenerator } from './util/generator/config.generator';
import { LoginDto } from './dto/login.dto';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IBaseResponse } from '../category-manage/interfaces/iBaseResponse.dto';
import { initPath } from './functions/decorators/init-path.decorator';
import { CustomSubject, checkStatus } from './functions/decorators/check-status.decorator';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { 
    //this.validate();
    this.initUser();
    this.formConfigGenerator = new FormConfigGenerator();
    this.checkStatus();
    
  }

  @initPath('users')
  baseUrl:any;

  @checkStatus
  user$: CustomSubject<User> = new BehaviorSubject<User>({} as User) as CustomSubject<User>;
  filteringKeyword = new BehaviorSubject('All');
  formConfigGenerator;
  private _isLoggedIn = false;

  get user(){
    return this.user$;
  }

  get userId(){
    return this.user$.pipe(
      map(user => user.id)
    )
  }

  get currentUser(){
    return this.user$.value;
  }

  get isLoggedIn(){
    return this._isLoggedIn;
  }

  checkStatus(){
    this.user$.subscribe({
      next: user => {
        this._isLoggedIn = user?.id ? true : false;
      }
    })
  }

  validate(){
    const accessToken = localStorage.getItem('accessToken');
    const expired = this.jwtHelper.isTokenExpired(accessToken);
    if(expired){
      console.log('expired ,', expired);
      
    }
    this.initUser();
  }

  initUser(){
    const user = localStorage.getItem('user');
    if (user) {
      this.user$.next(JSON.parse(user) as User);
    }
  }

  setUser(user: User){
    this.user$.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser(){
    this.user$.next({} as User);
    localStorage.removeItem('user');
  }

  setToken(res: any){
    const accessToken = res.data.accessToken;
    localStorage.setItem('accessToken', accessToken);
  }

  extractUser(res: any){
    const token = this.jwtHelper.decodeToken(res.data.accessToken);
    console.log('테스트중', token);
    return token.payload as User;
  }

  login<T>(param: LoginDto){
    return this.http.post<T>(this.baseUrl + '/login', param);
    // return this.http.post<T>(this.baseUrl + '/login', param).pipe(
    //   tap(res => {
    //     this.setToken(res);
    //   }),
    //   map(res => {
    //     const user = this.extractUser(res);
    //     return user;
    //   }),
    // );
  }

  signup<T>(param: CreateUserDto){
    return this.http.post<T>(this.baseUrl, param);
  }

  getUserList<T>(){
    const params = new HttpParams()
      .set('role', RoleId.User);

    console.log(params);
    return this.http.get<T>(`${this.baseUrl}/roles`, { params });
  }

  getAdminList<T>(){
    const params = new HttpParams()
      .set('role', RoleId.SuperAdmin);
    console.log(params);
    
    return this.http.get<T>(`${this.baseUrl}/roles`, { params });
  }

  updateUser<T>(param: UpdateUserDto){
    return this.http.post<T>(this.baseUrl + '/update', param);
  }

  updateAdmin<T>(param: UpdateAdminDto){
    return this.http.post<T>(this.baseUrl + '/admin', param);
  }

  updateUserRole<T>(user:User){
    return this.http.patch<T>(this.baseUrl + '/roles', user);
  }

  updatePassword<T>(param : UpdatePassowdDto){
    return this.http.post<T>(this.baseUrl + '/init', param);
  }

  validatePassword<T>(param: ValidatePasswordDto){
    return this.http.post<T>(this.baseUrl + '/valid', param);
  }

  resetPassword<T>(param: ResetPasswordDto){
    return this.http.post<T>(this.baseUrl + '/reset', param);
  }

  deleteUser<T>(userIdx: number){
    return this.http.post<T>(this.baseUrl+'/delete', {userIdx});
  }

  testJwt(){
    return this.http.post(this.baseUrl+'/guards', {});
  }

}
