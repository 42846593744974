import { ValidatorFn } from "@angular/forms";
import { email } from "../../functions/formcontrol/email.validator";
import { passwordMatchValidate } from "../../functions/formcontrol/password-match.validator";
import { passwordValidator } from "../../functions/formcontrol/password.validator";
import { requiredForm } from "../../functions/formcontrol/required.validator";

export type ValidatorMap = {
    requiredForm: () => ValidatorFn;
    passwordValidator: () => ValidatorFn;
    passwordMatchValidate: () => ValidatorFn;
    email: () => ValidatorFn;
}

export const validatorMapper = (key: string = '필수값'): ValidatorMap => {
    return {
        requiredForm: () => requiredForm(key),
        passwordValidator: () => passwordValidator(),
        passwordMatchValidate: () => passwordMatchValidate(),
        email: () => email()
    }
}
// export const validatorMapper =  {
//     requiredForm: (key: string) => requiredForm(key),
//     passwordValidator: () => passwordValidator(),
//     passwordMatchValidate: () => passwordMatchValidate(),
//     email: () => email()
// }