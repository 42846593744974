import { createAction, props } from "@ngrx/store";
import { INode } from "../../../pages/chatflow/interfaces/chatflow.interface";

export const addNode = createAction(
  '[Node Action] Add Node',
  props<{ data: INode }>()
);

export const updateNode = createAction(
  '[Node Action] Add Node',
  props<{ data: INode }>()
);

export const clearNode = createAction('[Node Action] Clear Node');