import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";

const NODE_NAME_INPUT = 'INPUT';

@Directive({
    selector: '[domChange]'
})
export class DomChangeDirective implements OnDestroy {
    private changes!: MutationObserver;

    @Output()
    public domChange = new EventEmitter();

    constructor(private elementRef: ElementRef){
        const element = this.elementRef.nativeElement;

        this.changes = new MutationObserver((mutations : MutationRecord[]) => {
            // console.log(mutations);
            if(mutations){
                this.initDomCompleted();
            }

            // 하위노드에서 특정 노드(input) 찾기
            // for(let mutation of mutations){
            //     const addedNodes = Array.from(mutation.addedNodes);
            //     const myElement = addedNodes.find(node => {
            //         console.log(node.nodeName);
            //         return node.nodeName === NODE_NAME_INPUT
            //     });
            //     if(myElement){
            //         this.findDom(myElement);
            //         return;
            //     }
                
            // };
        });

        this.changes.observe(element, {
            attributes: true, // 관찰 대상의 속성을 관찰
            // childList: true, // 관찰 대상의 자식(자손 아님)을 관찰
            // characterData: true, // 관찰 대상의 데이터 변화를 관찰
            // 위의 세가지가 관찰 대상을 지정하는 속성으로
            // 이외의 속성은 위 세가지에 더해 추가적으로 탐색할 때 사용되는 속성들임
            // 따라서 위의 세가지 속성중 하나는 필수로 설정해줘야함
            // subtree: true // 관찰 대상의 자식 노드들도 탐색
        })
    }

    initDomCompleted(){
        this.domChange.emit();
        this.disconnectObserve();
    }

    disconnectObserve(){
        this.changes.disconnect();
    }

    ngOnDestroy(): void {
        this.changes.disconnect();
    }
}