import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormFieldData } from '../../interfaces/dynamic-form-option.interface';
import { AbstractFormFeild } from '../../components/abstracts/form-field.abstract';

@Component({
  selector: 'app-password-confirmfield',
  templateUrl: './password-confirmfield.component.html',
  styleUrl: './password-confirmfield.component.scss'
})
export class PasswordConfirmfieldComponent extends AbstractFormFeild implements OnInit {
  @Input() initData!: FormFieldData;
  @Output() event = new EventEmitter<FormControl>();
  @Output() viewInit = new EventEmitter<ElementRef>();

  @ViewChild('passwordInput', { static: true }) passwordInput!: ElementRef;
  hide = true;

  ngOnInit(): void {
    this.init(this.initData);
  }

  domChange(event: MutationRecord){
    this.viewInit.next(this.passwordInput);
  }
}