import { Injectable } from "@angular/core";
import { User } from "../../pages/membership/interfaces/user.interface";
import { CategorySelectService } from './category-select.service';
import { RoleId } from "../../pages/membership/util/enum/role-id.enum";

@Injectable({ providedIn: 'root' })
export class CurrentUserService{

    public initial: User = { //빈 초기 데이터 하드 코딩
        'userIdx': 0,
        'id': '',
        'password': '',
        'name': '',
        'email': '',
        'department': '',
        'phone': '',
        'joinDate': new Date(),
        'isPasswordReset': false,
        'role': {
            'roleId': 0,
            'roleName': 'User'
        }
    };

    public userData: User = this.initial;
    
    constructor(public vectordbService: CategorySelectService) {
        this.getUser();
    }

    public getUser() {
        const user = localStorage.getItem('user');
        if (user) {
            this.userData = JSON.parse(user);
            return this.userData;
        }
        return null;
    }

    public setUser(user: User) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    public removeUser() {
        this.userData = this.initial;
        this.vectordbService.remove();
        localStorage.removeItem('user');
    }

    public isLogin() {
        if (this.userData.name === '')  return false;
        return true;
    }

    public isSuperAdmin() {
        if (this.userData.role.roleId === RoleId.SuperAdmin) return true;
        return false;
    }

    public isAdmin() {
        if (this.userData.role.roleId === RoleId.Admin) return true;
        return false;
    }

}