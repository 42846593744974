import { EventEmitter } from "@angular/core";
import { FormControl, ValidatorFn } from "@angular/forms";
import { FieldConfig, FormFieldConfig } from "../../interfaces/dynamic-form.interface";
import { FormFieldOption } from "../../util/generator/form.field.option";
import { FieldConfigData, FormFieldData } from "../../interfaces/dynamic-form-option.interface";
import { DynamicField } from "./dynamic-field.abstract";
import { FieldOption } from "../../util/generator/field.option";

/**
 * 폼 영역 데이터 바인딩 클래스
 */
export abstract class AbstractButtonFeild extends DynamicField {
    config!: FieldConfig;

    init(initData: FieldConfigData){
        const { key, title, initValue } = initData;
        const config = this.generator.generate(FieldOption)
            .key(key).title(title).initValue(initValue).build();
        this.config = config;
    }
}