<mat-card
  [style.width]="width"
  [style.height]="height"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  class="text-ellipsis"
>
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      {{ content }}
    </p>
  </mat-card-content>
</mat-card>