// export const categoryRootCode = {
//     personal : {
//         type: 'personal',
//         name: '개인 카테고리',
//         minRole: 100,
//         isSelectable: true,
//     },
//     common : {
//         type: 'common',
//         name: '공통 카테고리',
//         minRole: 200,
//         isSelectable: true,
//     },
//     adminPersonal : {
//         type: 'personal',//백에드에서 type: personal에/ 권한으로 분기친다
//         name: '관리자 카테고리',
//         minRole: 200,
//         isSelectable: true,
//     },
// }

// export type TCategoryCode = keyof typeof categoryRootCode;

interface ICategoryCode {
    key: string;
    value: {
        type: string,
        name: string,
        minRole: number,
        isSelectable: boolean,
    }
}
export class CategoryCode{
    //enum처럼 쓰기 위한 이름
    personal = 'personal';
    common = 'common';
    adminPersonal = 'adminPersonal';

    codes: ICategoryCode[] = [
        {
            key: 'personal',
            value: {
                type: 'personal',
                name: '개인 카테고리',
                minRole: 100,
                isSelectable: true,
            }
        },
        {
            key: 'common',
            value: {
                type: 'common',
                name: '공통 카테고리',
                minRole: 200,
                isSelectable: true,
            }
        },
        {
            key: 'adminPersonal',
            value: {
                type: 'personal',
                name: '관리자 카테고리',
                minRole: 200,
                isSelectable: false,
            },
        },
    ]
    //롤에 따라 가져오게
    getByRoles(roleId: number){
        return this.codes.filter(d => d.value.minRole <= roleId)
    }

    //카테고리 선택에서 가져오게
    getSelectables(){
        return this.codes.filter(d => d.value.isSelectable === true);
    }
}