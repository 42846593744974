import { KeyValue } from "@angular/common";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { IChatflow } from "../interface/chatflow.interface";
import { LogicChat } from "../logics/chat.chatflow";
import { LogicChatbot } from "../logics/chatbot.chatflow";
import { LogicComplain } from "../logics/complain.chatflow";
import { LogicMetadata } from "../logics/metadata.chatflow";
import { LogicMrc } from "../logics/mrc.chatflow";
import { LogicSummary } from "../logics/summary.chatflow";
import { LogicLearn } from "../logics/learn.chatflow";
import { LogicHistory } from "../logics/history.chatflow";

export class LogicManager {
  public logicComponents: KeyValue<string, IChatflow>[] = [];

  constructor(public liskovChatflow: LiskovChatflowModel) {}

  init(logics: any) {
    this.logicComponents.push({key : 'chatbot', value: new LogicChatbot(this.liskovChatflow)});
    this.logicComponents.push({key: 'chat', value: new LogicChat(this.liskovChatflow)});
    this.logicComponents.push({key: 'mrc', value: new LogicMrc(this.liskovChatflow)});
    this.logicComponents.push({key: 'metadata', value: new LogicMetadata(this.liskovChatflow)});
    this.logicComponents.push({key: 'summary', value: new LogicSummary(this.liskovChatflow)});
    this.logicComponents.push({key: 'summarynews', value: new LogicSummary(this.liskovChatflow)});
    this.logicComponents.push({key: 'learn', value: new LogicLearn(this.liskovChatflow)});
    this.logicComponents.push({key: 'history', value: new LogicHistory(this.liskovChatflow)});
    this.logicComponents.push({key: 'complain', value: new LogicComplain(this.liskovChatflow)});

    this.setData(logics);
  }

  private setData(logics: any) {
    logics.scenarios.forEach((d: any) => {
      this.find(d.key)?.init(d);
    })
  }

  find<T extends IChatflow>(key: string): T {
    return this.logicComponents.find(d => d.key === key)?.value as T;
  }
}
