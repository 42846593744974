import { FieldConfigData } from "../../interfaces/dynamic-form-option.interface";
import { FieldConfig } from "../../interfaces/dynamic-form.interface";
import { FormConfigGenerator } from "../../util/generator/config.generator";

/**
 * 동적 모달 데이터 바인딩 추상 클래스
 */
export abstract class DynamicField {
    abstract initData: FieldConfigData;
    abstract config: FieldConfig;
    generator = new FormConfigGenerator();
    
    abstract init(initData: FieldConfigData): void;
}