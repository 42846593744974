<!-- <mat-menu></mat-menu> -->
<div [style.height]="'100%'">
    @if (this.treeService.datasource.length > 0) {
        <app-treerow [id]="this.treeService.datasource[0].id"></app-treerow>
    }
</div>

<!-- <div [style.height.%]="100" (contextmenu)="clickRight($event, menu)">
    @if (this.treeService.datasource[0]){
        @for (item of this.treeService.datasource[0].children; track $index) {
            <app-treerow [id]="item"></app-treerow>
        }
    }
    <app-menu #menu  [menuData]="treeService.menuClass.menuData" [neededData]="this"></app-menu>
</div> -->