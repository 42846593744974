import { ApplicationRef, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { DataAbstract } from '../classes/data.abstract';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {

  constructor(public appRef: ApplicationRef) { }

  public createComponent<T extends DataAbstract>(
    component: Type<T>,
    container: ViewContainerRef,
    data?: data
  ): ComponentRef<T> {
    const ref = container.createComponent(component);
    const instance = ref.instance;
    instance.data = data;
    return ref;
  }

  public destroyComponent<T>(ref: ComponentRef<T>) {
    this.appRef.detachView(ref.hostView);
    ref.destroy();
  }

}
