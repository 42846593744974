import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { IBaseResponse } from "../../shared/interfaces/base-response.interface";
import { inject } from "@angular/core";

export class CodeHttp {
    private readonly httpService = inject(HttpService);

    getCommonCode(groupcode: string) {
        return this.httpService.Get(`/code/common/${groupcode}`) as Observable<ICommonCodeResponse>;
    }
}

export interface ICommonCodeResponse extends IBaseResponse<{
    code: string;
    name: string;
    groupcode: string;
    groupname: string;
}[]> {

}