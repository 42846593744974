import { Role } from "../interfaces/role.interface";
import { RoleId } from "../util/enum/role-id.enum";
import { RoleName } from "../util/enum/role-name.enum";

export class CreateUserDto{
    public id: string;
    public name: string;
    public password: string;
    public email: string;
    public department: string;
    public phone: string;
    public role: Role;
    public isPasswordReset: boolean;
    public insertDate: Date;

    constructor(){
        this.id = '';
        this.name = '';
        this.password = '';
        this.email = '';
        this.department = '';
        this.phone = '';
        this.role = {
            roleId: RoleId.User,
            roleName: RoleName.User
        } as Role;
        this.isPasswordReset = false;
        this.insertDate = new Date();
    }
}