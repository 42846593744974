import { map } from "rxjs";
import { GlobalService } from "../../../../core/services/global.service";
import { ICategoryGetRequest } from "../../interfaces/category/category.dto";

export class CategoryShared{
    constructor(protected globalService: GlobalService){
        
    }
    getCategories(request: ICategoryGetRequest) {
        return this.globalService.dalService.categoryHttp!.get(request).pipe(map(d => {
            return d.data.map(d => {
                const { children, ...rest } = d;
                return {
                    ...rest,
                    children: JSON.parse(d.children)
                }
            });
        }));
    }
}