import { AfterViewInit, Component } from '@angular/core';
import { Observable, Subscription, interval, map } from 'rxjs';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrl: './swiper.component.scss'
})
export class SwiperComponent extends DataAbstract implements AfterViewInit {

  public items: any = [];

  public isLoading: boolean = true;
  private subscription!: Subscription;

  ngAfterViewInit(): void {

    const swiperParams: SwiperOptions = {
      modules: [Navigation, Pagination, Autoplay],
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      spaceBetween: 50,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
      // 루프 설정 (옵션)
      pagination: {
        el: ".swiper-pagination",
      },
    };

    const swiper = new Swiper('.swiper', swiperParams);

    this.subscription = this.getLoadingObservable().subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  public override dataInit(): void {
    this.items = [...this.data];
  }

  getLoadingObservable(): Observable<boolean> {
    return interval(1000).pipe(
      map((value) => {
        return !this.isLoading;
      })
    );
  }

  ngOnDestroy() {
    // 구독 해제
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
