import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrl: './chips.component.scss'
})
export class ChipsComponent extends DataAbstract {

  public items: Array<any> = [];
  public x!: number;
  public y!: number;

  //@HostBinding('style') style = 'position: absolute; right: 10px;';
  @Output() selectChange = new EventEmitter<any>();

  public override dataInit(): void {
    this.items = [...this.data.items];
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }

  public onSelecteChange(event: any) {
    this.selectChange.emit(event);
  }

}
