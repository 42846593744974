import { ComponentRef } from "@angular/core";
import { QuestionBalloonComponent } from "../../../shared/components/molecules/question-balloon/question-balloon.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { ChatflowLogic } from "../abstract/logic.chatflow.abstract";
import { LogicHistory } from "./history.chatflow";
import { DataAbstract } from "../../classes/data.abstract";
import { Observable } from "rxjs";
import { AnswerBalloonHTMLComponent } from "../../../shared/components/molecules/answer-balloon-html/answer-balloon-html.component";

export class LogicChat extends ChatflowLogic {

  public card!: ComponentRef<DataAbstract>;
  private type: string = '';
  //private data: any;

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }

  private setComponentType(data?: data): string {
    let result = 'answer';
    switch (data.component) {
      case AnswerBalloonHTMLComponent:
        result = 'answer';
        break;
      case QuestionBalloonComponent:
        result = 'question';
        break;
    }
    return result;
  }

  override prepare(data?: data): void {
    this.card = this.liskovChatflow.service.factoryService.createComponent(
      data.component,
      this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.container,
      data.message
    );

    this.liskovChatflow.service.dynamicLayoutService.childComponentRefs.push(this.card);
    this.liskovChatflow.service.dynamicLayoutService.chatScrollBody.scrollTop = this.liskovChatflow.service.dynamicLayoutService.chatScrollBody.scrollHeight;
    this.type = this.setComponentType(data);
  }

  override create(data?: data): void {
    this.card.instance.data = data.message;
    this.liskovChatflow.service.dynamicLayoutService.chatScrollBody.scrollTop = this.liskovChatflow.service.dynamicLayoutService.chatScrollBody.scrollHeight;
    this.liskovChatflow.service.dynamicLayoutService.answerContainer = this.card as ComponentRef<AnswerBalloonHTMLComponent>;
  }

  override clear(data?:data): Observable<any> {
    this.card.instance.isProgress = false;
    return (this.liskovChatflow.logicManager.find('history') as LogicHistory).computed({
      type: this.type,
      message: data,
      metadata: this.liskovChatflow.service.historyService.historyMetadata
    });
  }

}
