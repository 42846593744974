import { Observable } from "rxjs";
import { ICategoryCreateRequest, ICategoryCreateResponse, ICategoryDeleteRequest, ICategoryDeleteResponse, ICategoryGetRequest, ICategoryGetResponse, ICategoryMoveRequest, ICategoryMoveResponse, ICategoryNameChangeRequest, ICategoryNameChangeResponse, ICategoryPersonalCreate } from "../../pages/category-manage/interfaces/category/category.dto";
import { HttpService } from "../services/http.service";
import { ICategoryRest } from "./category-manage.http";
import { inject } from "@angular/core";

export class CategorManageLegacyHttp implements ICategoryRest{
    private readonly httpService = inject(HttpService);

    move(request: ICategoryMoveRequest): Observable<ICategoryMoveResponse> {
        console.log('move request', request);
        return this.httpService.Post('/category-legacy/patch/move', request) as Observable<ICategoryMoveResponse>;
    }

    get(request: ICategoryGetRequest): Observable<ICategoryGetResponse> {
        //params는 queryString이다.
        return this.httpService.Get(`/category-legacy/${request.type}/${request.id}`) as Observable<ICategoryGetResponse>;
    }

    create(request: ICategoryCreateRequest): Observable<ICategoryCreateResponse> {
        return this.httpService.Post('/category-legacy', request) as Observable<ICategoryCreateResponse>;
    }

    delete(request: ICategoryDeleteRequest): Observable<ICategoryDeleteResponse> {
        return this.httpService.Post('/category-legacy/delete', request) as Observable<ICategoryDeleteResponse>;
    }

    rename(request: ICategoryNameChangeRequest): Observable<ICategoryNameChangeResponse> {
        return this.httpService.Post('/category-legacy/patch/rename', request) as Observable<ICategoryNameChangeResponse>;
    }

    createPersonal(request: ICategoryPersonalCreate): Observable<ICategoryCreateResponse> {
        return this.httpService.Post('/category-legacy/personal', request) as Observable<ICategoryCreateResponse>;
    }

}