import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { RowInputComponent } from '../../pages/sketchflow/components/row-input/row-input.component';
import { IValue } from '../../pages/sketchflow/classes/data/sketchflow.data';
import { BehaviorSubject } from 'rxjs';
import { RowComponent } from '../../pages/sketchflow/components/row/row.component';
import { BoxComponent } from '../../pages/sketchflow/components/box/box.component';

export interface IRowContainer {
  id: string;
  container: ViewContainerRef | any;
  boxContainers: ViewContainerRef[] | any;
}

@Injectable({
  providedIn: 'root'
})
export class LogicSketchflowService {

  public sketchflowDrawer!: MatDrawer;
  public sketchflowDrawerPosition: 'start' | 'end' = 'end';
  
  public rows: ComponentRef<RowComponent>[] = [];
  // public boxs: ComponentRef<BoxComponent>[] = [];
  public rowDetailContainer!: any;
  public rowInputComponent: ComponentRef<RowInputComponent> | null = null;

  public currentPage: string = '';

  private rowSource = new BehaviorSubject<{
    id: string,
    prop: string,
    data: Partial<IValue>
  }>({
    id: '',
    prop: '',
    data: { id: '' }
  });
  public rowData = this.rowSource.asObservable();
  
  private boxSource = new BehaviorSubject<{
    rowId: string,
    id: string,
    prop: string,
    data: Partial<IValue>
  }>({
    rowId: '',
    id: '',
    prop: '',
    data: { id: '' }
  });
  public boxData = this.boxSource.asObservable();

  constructor() { }

  public onChangeRowData(id: string, prop: string, event: any) {
    this.rowSource.next({ id: id, prop: prop, data: event });
  }

  public onChangeBoxData(rowId: string, id: string, prop: string, event: any) {
    this.boxSource.next({ rowId: rowId, id: id, prop: prop, data: event });
  }

  public getValue(key: string) {
    const value = localStorage.getItem(key);
    if (!value) return;
    return JSON.parse(value).value;
  }

  public setValue(key: string, data: data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public setProp(key: string, prop: string, id: string, data: data) {
    const sketchFlow = this.getValue(key);
    const currentIndex = sketchFlow.findIndex((item: IValue) => item.id === id);

    switch(prop) {
      case 'name':
        sketchFlow[currentIndex][prop] = data;
        break;
      case 'data':
        sketchFlow[currentIndex][prop].push(data);
        break;
      case 'width':
        sketchFlow[currentIndex].size.width = data;
        break;
      case 'height':
        sketchFlow[currentIndex].size.height = data;
        break;
      case 'x':
        sketchFlow[currentIndex].position.x = data;
        break;
      case 'y':
        sketchFlow[currentIndex].position.y = data;
        break;
      case 'path':
        sketchFlow[currentIndex][prop] = data;
        break;
      case 'colSpan':
          sketchFlow[currentIndex].cell.colSpan = data;
          break;
      case 'boxs':
        sketchFlow[currentIndex][prop] = data;
        break;
    }

    const newSketchFlow = {
      "key": key,
      "value": [
        ...sketchFlow.slice(0, currentIndex),
        sketchFlow[currentIndex],
        ...sketchFlow.slice(currentIndex + 1)
      ]
    };

    this.setValue(key, newSketchFlow);
  }

}