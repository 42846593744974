import { Component, EventEmitter, Output } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrl: './chat-box.component.scss'
})
export class ChatBoxComponent extends DataAbstract {

  public width!: string;
  public height!: string;
  public x!: number;
  public y!: number;

  @Output() dragover = new EventEmitter<DragEvent>();
  @Output() drop = new EventEmitter<DragEvent>();
  @Output() dragleave = new EventEmitter<DragEvent>();

  public override dataInit(): void {
    this.width = this.data.size.width;
    this.height = this.data.size.height;
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragover.emit(event);
  }

  public onDrop(event: DragEvent) {
    console.log('chatBox drop');
    event.preventDefault();
    event.stopPropagation();
    this.drop.emit(event);
  }

  public onDragLeave(event: DragEvent) {
    this.dragleave.emit(event);
  }

}
