<mat-expansion-panel 
  style="background-color: transparent;"
  class="text-ellipsis"
  [id]="index"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
> <!-- [expanded]="index === this.globalService.solutionService.components.length - 1 ? isPanelOpen : !isPanelOpen" -->
  <mat-expansion-panel-header>
    <mat-panel-title class="text-ellipsis">
      <span style="font-weight: 600; font-size: small; margin-top: 10px; color: #3584ce;">
      {{ documentTitle }} / {{documentPage}}
      </span>
    </mat-panel-title>
    <!-- <mat-panel-description>
      {{ documentTitle }} / {{documentPage}}
    </mat-panel-description> -->
  </mat-expansion-panel-header>
  <div class="content-container">
    <!-- <div [innerHTML]="documentContent | safeHtml"></div> -->
    <pre style="white-space: pre-wrap;">{{ documentContent }}</pre>
    <div class="content-page">{{ documentPage }}</div>
  </div>
</mat-expansion-panel>
