import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { addExpansionPanelBox, sortExpansionPanelBox } from "../store/expansion-panel-box/expansion-panel-box.action";
import { triggerAction } from "../store/trigger/trigger.action";
import { deliverAction } from "../store/deliver/deliver.action";
import { resizeAction } from "../store/layout/layout.action";
import { ILayout } from "../store/layout/layout.reducer";
import { IExpansionPanelBox } from './../store/expansion-panel-box/expansion-panel-box.reducer';
import { addNode, clearNode, updateNode } from "../store/node/node.action";
import { INode } from "../../pages/chatflow/interfaces/chatflow.interface";

@Injectable({ providedIn: 'root' })
export class StoreService {

  constructor(public store: Store) {}

  public add(data: data) {
    this.store.dispatch(addExpansionPanelBox({
      expansionPanelBox: data
    }));
  }

  public addNode(data: INode) {
    this.store.dispatch(addNode({
      data: {
        id: data.id,
        type: data.type,
        node: {
          category: data.node.category,
          result: data.node.result,
          isAwait: data.node.isAwait
        },
        index: data.index,
        left: data.left,
        right: data.right
      }
    }))
  }

  public updateNode(data: INode) {
    this.store.dispatch(updateNode({
     data: {
        id: data.id,
        type: data.type,
        node: {
          category: data.node.category,
          result: data.node.result,
          isAwait: data.node.isAwait
        },
        index: data.index,
        left: data.left,
        right: data.right
      }
    }))
  }

  public clearNode() {
    this.store.dispatch(clearNode());
  }

  public sortBox(data: IExpansionPanelBox) {
    this.store.dispatch(sortExpansionPanelBox({
      expansionPanelBox: {
        components: data.components,
        isSorted: data.isSorted
      }
    }));
  }

  public trigger(triggerType: string){
    this.store.dispatch(triggerAction({trigger: {type: triggerType}}));
  }

  public deliver(deliverType: string, data: any){
    this.store.dispatch(deliverAction({deliver: {type: deliverType, data: data}}));
  }

  public resize(data: ILayout) {
    this.store.dispatch(resizeAction({ 
      layoutData: {
        leftWidth: data.leftWidth,
        rightWidth: data.rightWidth
      } 
    }));
  }
  
}