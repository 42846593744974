import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SlotDefaultComponent } from '../../components/default/default.component';
import { State } from '../../service/docking.service';
import { GlobalService } from '../../../../core/services/global.service';


@Component({
  selector: 'app-slot-bottom-sheet',
  templateUrl: './slot-bottom-sheet.component.html',
  styleUrl: './slot-bottom-sheet.component.scss'
})
export class BottomSlotSheetComponent {

  @ViewChild('promptText', { read: ElementRef, static: true }) promptText!: ElementRef;

  public prompt!: string;

  public selectedValue: string = '';

  stateCtrl = new FormControl('');
  filteredStates: Observable<State[]>;

  

  constructor(
    public globalService: GlobalService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSlotSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.prompt = data.prompt;

    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(state => (state 
        ? this._filterStates(state) 
        : this.globalService.slotService.dockingService.states.filter(state => state.visible).slice() // Filter before slicing
      )),
    );
  }

  private _filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();
  
    return this.globalService.slotService.dockingService.states.filter(state => 
      state.name.toLowerCase().includes(filterValue) && state.visible // Add this check
    );
  }
  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }

  action() {
    console.log('action', this.selectedValue);
    this.globalService.slotService.dockingService.setDefault(this.selectedValue);
    this.closeBottomSheet();
  }

  public clearSlot() {
    this.globalService.slotService.dockingService.slotData = [];
    localStorage.setItem('slot', JSON.stringify(this.globalService.slotService.dockingService.slotData));
    this.globalService.httpService.snackBar('초기화 되었습니다.');
  }

}
