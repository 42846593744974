import { Component, EventEmitter, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileDialogComponent } from '../../../../chatbot/components/file-dialog/file-dialog.component';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrl: './expansion-panel.component.scss'
})
export class ExpansionPanelComponent extends DataAbstract {

  public isPanelOpen: boolean = true;

  public index!: number;
  public question!: string;
  public documentTitle!: string;
  public documentContent!: string;
  public documentPage!: string;
  public x: number = 5;
  public y: number = 5;

  @Output() click = new EventEmitter<any>();

  private readonly dialog = inject(MatDialog);

  public override dataInit(): void {
    this.index = this.data.index;
    this.question = this.data.question;
    this.documentTitle = this.data.documentTitle;
    this.documentContent = (this.data.documentContent as string)?.replaceAll('|||', '\n');
    this.documentPage = this.data.documentPage;

    // this.index = this.data.panel.index;
    // this.question = this.data.panel.question;
    // this.documentTitle = this.data.panel.documentTitle;
    // this.documentContent = this.data.panel.documentContent;
    // this.documentPage = this.data.panel.documentPage;
    // this.x = this.data.position.x;
    // this.y = this.data.position.y;
  }

  public onClick(event: MouseEvent) {
    event.stopPropagation();
    this.openFileDialog();
    this.click.emit({
      event,
      name: this.documentTitle
    });
  }

  public openFileDialog() {
    const dialogRef = this.dialog.open(FileDialogComponent, {
      data: {
        name: this.documentTitle
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.httpService.GetBlob(`/fileManage/download/name/${this.documentTitle}`).subscribe(d => {
          const blob = new Blob([d.body!], { type: d.headers.get('content-type')! });
          const linkEl = document.createElement('a');
          linkEl.style.display = 'none';
          const url = window.URL.createObjectURL(blob);//브라우저가 접근할 수 있게 object를 url로 변환한다.
          linkEl.href = url;
          linkEl.download = `${this.documentTitle}`; // 파일 이름 및 확장자를 지정합니다.
          document.body.appendChild(linkEl);
          linkEl.click();
          document.body.removeChild(linkEl);
          window.URL.revokeObjectURL(url);//이거 안하면 메모리에 남아있음
        });
      }
    });
  }

}
