import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { FileSocket } from "../../pages/category-manage/classes/file-socket.class";

@Injectable({ providedIn: 'root' })
export class SocketService {

  public id!: string;
  public isConnected: boolean = false;
  public fileSocket = new FileSocket();
  
  constructor(private socket: Socket) {
    this.socket.fromEvent('createdSocketId').subscribe((d: any) => {
      this.id = d.message;
    });
  }
  
  public getMessage(eventMessage: string) {
    return this.socket.fromEvent(eventMessage);
  }

  public sendMessage(eventMessage: string, param: any) {
    this.socket.emit(eventMessage, param);
  }
  
}
