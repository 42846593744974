import { createAction, props } from "@ngrx/store";
import { IExpansionPanelBox, IExpansionPanelBoxData } from "./expansion-panel-box.reducer";

export const addExpansionPanelBox = createAction(
  '[Expansion Panel Box] Add Box',
  props<{ expansionPanelBox: IExpansionPanelBoxData[] }>()
);

export const sortExpansionPanelBox = createAction(
  '[Expansion Panel Box] Sort Box',
  props<{ expansionPanelBox: IExpansionPanelBox }>()
);