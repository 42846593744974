import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IMoveData, ITreeCheck, TreeviewStore } from '../tree.store';
import { ITreeDatasource } from '../tree.interface';
import { IMenu } from '../../../components/menu-item/menu/menu.interface';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CheckService } from '../service/check.service';
import { TreeService } from '../service/tree.service';

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrl: './treeview.component.scss',
  providers: [TreeviewStore, CheckService, TreeService]
})
export class TreeviewComponent<D extends ITreeDatasource> implements OnInit, OnChanges {
  @Input() datasource!: Array<D>;
  @Input() dragDisabled: boolean = false;
  @Input() checkDisabled: boolean = true;
  @Input() menuClass: IMenu = {//나중에 바깥으로 뺄 수 있겠다.
    menuData: []
  };
  @Input() checked: Array<number> = [];//잠시 안씀
  @Input() clickedId: number = -1;
  @Input() who: string = '';
  @Output() clickEvent = new EventEmitter<number>();
  @Output() movedParent = new EventEmitter<number>();
  @Output() checkEvent = new EventEmitter<ITreeCheck>();//나중에 checkEvent도 없애야겠다. clickEvent 하나로 쓰고 디렉티브에서 관리하는 편이 나을지도..?
  @Output() moveData = new EventEmitter<IMoveData>();

  clickedTreeId$ = this.treeviewStore.clickedTreeId$;
  moveEvent$ = this.treeviewStore.moveEvent$;
  checkEvent$ = this.treeviewStore.checkEvent$;

  constructor(
    private readonly treeviewStore: TreeviewStore,
    private readonly checkService: CheckService,
    public readonly treeService: TreeService<D>,
  ) {}

  ngOnInit(): void {
    this.treeService.dragDisabled = this.dragDisabled;
    this.treeService.checkDisabled = this.checkDisabled;
    this.treeService.menuClass = this.menuClass;
    //카테고리 클릭 이벤트 트래킹
    this.clickedTreeId$.subscribe(d => {
      if (d.id === -1) return;
      this.clickEvent.emit(d.id);
    });

    //카테고리 이동 이벤트 트래킹
    this.moveEvent$.subscribe(d => {
      //console.log('movemove');
      if (d.movedParentId === -1) return;
      this.movedParent.emit(d.movedParentId);
      this.moveData.emit(d);
    });

    this.checkEvent$.subscribe(d => {
      if (d.clickedTreeId === -1) return;
      this.checkEvent.emit(d);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['datasource'].firstChange){//이거 출력 하는 동안 다른데서 값 바뀜.
    //   console.log('first! datasource', JSON.stringify(changes['datasource']));
    //   //1. 처음에는 []가 할당됨
    //   //2. 두 번째는 response로 가져온 값이 아래에 할당 됨. 고로 ngOnChanges는 두 번이 호출 됨!
    // }

    this.treeService.datasource = this.datasource;
    //console.log('who?', this.who, this.checked, changes, this.clickedId);
    this.checkService.checked = this.checked;
    // console.log('changes', this.who, changes);//두 번 호출됨. 한 번은 datasource= [], clickedId, 두 번은 datasource[]fetch 한 값.
    if(!changes['clickedId']) return;
    if(changes['clickedId'].isFirstChange()){//처음 바뀌었을 때만 클릭 처리 시킴
      this.treeviewStore.treeClick(this.clickedId);
    }
  }
}
