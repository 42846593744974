<!-- <app-mrc-selected></app-mrc-selected> -->
<h1 mat-dialog-title>카테고리를 선택하세요</h1>
<div mat-dialog-content class="content">
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
        @for (item of categoryCode.getSelectables(); track $index) {
            <mat-tab [label]="item.value.name">
                <app-treeview #treeview2 [datasource]="categories" 
                (clickEvent)="onTreeClicked(item.key, $event, treeview2)" [who]="'mrc 선택' + item.value.name"
                    [clickedId]="globalService.vectordbService.selectedCategories[item.key].id"></app-treeview>
            </mat-tab>
        }
    </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onYesClick()">확인</button>
    <button mat-button (click)="onNoClick()">닫기</button>
</div>