import { DynamicField, FieldConfig } from "../../interfaces/dynamic-form.interface";

export class FieldOption implements DynamicField{
    config: FieldConfig = {
        key: "",
        title: "",
        initValue: undefined
    };

    constructor(){

    }

    key(key: string): this {
        this.config.key = key;
        return this;
    }

    title(title: string): this {
        this.config.title = title;
        return this;
    }

    initValue(initValue: string): this {
        this.config.initValue = initValue;
        return this;
    }

    build(): FieldConfig {
        return this.config;
    }
}