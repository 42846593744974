import { Component } from '@angular/core';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-question-balloon',
  templateUrl: './question-balloon.component.html',
  styleUrl: './question-balloon.component.scss'
})
export class QuestionBalloonComponent extends DataAbstract {

  public question!: string;

  public override dataInit(): void {
    this.question = this.data;
  }
}
