import { Component } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent extends DataAbstract {

  public title: string = '';
  public content: string = '';
  public width: string = '';
  public height: string = '';
  public x: number = 0;
  public y: number = 0;

  public override dataInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;
    this.width = this.data.size.width;
    this.height = this.data.size.height;
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }

}
