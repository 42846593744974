import { Router } from "@angular/router";
import { LayoutManager } from "../chatflows/model/manager.layout";
import { LogicManager } from "../chatflows/model/manager.logic";
import { GlobalService } from "../services/global.service";

//진짜 부모자식 관계 찾는데만 사용하기
export class LiskovChatflowModel {
  
  public scenarios!: Array<any>;
  public logicManager!: LogicManager;
  public layoutManager!: LayoutManager;
  
  // public layoutKeyWords: Array<string> = [];
  // public layoutContainers: Array<any> = []; // 화면을 그릴 컨테이너
  // 로직 넣은 배열 만들어야 함
  public logicKeyWords: Array<string> = [];
  public logicData: Array<any> = [];

  constructor(router: Router, jsonData: any, public service: GlobalService) {
    this.layoutManager = new LayoutManager(router, this);
    this.layoutManager.init(jsonData.layouts);

    this.logicManager = new LogicManager(this);
    this.logicManager.init(jsonData.logics);

    this.scenarios = jsonData.logics.scenarios;
  }

  public clear() {
    // this.layoutKeyWords = [];
    // this.layoutContainers = [];
    this.logicData = [];
  }

}