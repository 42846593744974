import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { inject } from "@angular/core";

export interface IChatHistoryRequest {
  userIdx: number;
  chatPageId: string;
  data?: any;
}

export interface IChatHistoryRest{
  create(request: IChatHistoryRequest): Observable<any>;
  update(request: IChatHistoryRequest): Observable<any>;
  get(userIdx: number): Observable<any>;
  delete(request: IChatHistoryRequest): Observable<any>;
}

export class ChatHistoryHttp implements IChatHistoryRest {

  private readonly httpService = inject(HttpService);

  create(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Post('/history', request);
  }

  update(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Post('/history', request);
  }

  get(userIdx: number): Observable<any> {
    return this.httpService.Get(`/history/${userIdx}`);
  }

  delete(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Delete(`/history/${request.userIdx}/${request.chatPageId}`);
  }

}