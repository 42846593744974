import { Injectable } from "@angular/core";
import { ActivationEnd, ActivationStart, ChildActivationEnd, ChildActivationStart, GuardsCheckEnd, GuardsCheckStart, NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RoutesRecognized, Scroll } from "@angular/router";
import { catchError, filter, retry, take } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MembershipService } from "../../pages/membership/membership.service";
import { initPath } from "../../pages/membership/functions/decorators/init-path.decorator";
import { DateTime, Interval } from "luxon";
import { InsertPageViewDto } from "../../pages/monitoring/dto/insert-page-view.dto";

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private router: Router,
        private client: HttpClient,
        private membershipService: MembershipService
    ){
        //this.tracking();
    }

    @initPath('monitoring')
    baseUrl: any;
    userIdx = -1;

    tracking(){
        this.router.events.pipe(
            filter(e => e instanceof NavigationStart),
        )
        .subscribe({
            next: (e: any) => {
                this.membershipService.user.pipe(
                    filter(user => !!user),
                    take(1)
                ).subscribe((user) => {
                    this.userIdx = user?.userIdx ?? -1;
                    const name = e.constructor.name as string
                    const methodName = name.charAt(0).toLowerCase() + name.slice(1);
                    (this as any)[methodName](e);
                })
                
            }
        })
    }

    insertLog(param: any){
        this.client.post(this.baseUrl, param).pipe(
            take(1),
            retry(3),
            catchError(e => {
                console.log(e);
                return e;
            })
        ).subscribe({
            next: result => {
                console.log(result);
            }
        });
    }

    createParam(url: string) {
        const luxon = DateTime.now();
        
        const param = new InsertPageViewDto();
        param.url = url;
        param.userIdx = this.userIdx;
        param.agent = window.navigator.userAgent;
        param.date = luxon.toFormat('yyyy-MM-dd');
        param.time = luxon.toFormat('hh:mm:ss');
        
        // {
        //     url,
        //     userIdx: this.userIdx,
        //     agent: window.navigator.userAgent,
        //     date: luxon.toFormat('yyyy-MM-dd'),
        //     time: luxon.toFormat('hh:mm:ss')
        // }
        return param;
    }

    navigationStart(e: NavigationStart){
        const url = e.url.split('/');
        const lastUrl = (url.pop()) ?? 'root';
        const param = this.createParam(lastUrl);
        this.insertLog(param);
    }

    routeConfigLoadStart(e: RouteConfigLoadStart){
        console.log('routeConfigLoadStart');
    }

    routeConfigLoadEnd(e: RouteConfigLoadEnd){
        console.log('routeConfigLoadStart');
    }

    routesRecognized(e: RoutesRecognized){
        console.log('routesRecognized');
    }

    guardsCheckStart(e: GuardsCheckStart){
        console.log('guardsCheckStart');
    }

    childActivationStart(e: ChildActivationStart){
        console.log('childActivationStart');
    }

    activationStart(e: ActivationStart){
        console.log('activationStart');
    }

    guardsCheckEnd(e: GuardsCheckEnd){
        console.log('guardsCheckEnd');
    }

    resolveStart(e: ResolveStart){
        console.log('resolveStart');
    }

    resolveEnd(e: ResolveEnd){
        console.log('resolveEnd');
    }

    activationEnd(e: ActivationEnd){
        console.log('activationEnd');
    }

    childActivationEnd(e: ChildActivationEnd){
        console.log('childActivationEnd');
    }

    navigationEnd(e: NavigationEnd){
        console.log('navigationEnd');
    }

    scroll(e: Scroll){
        console.log('scroll');
    }

}