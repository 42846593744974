import { Subscription } from "rxjs";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { IChatflow } from "../interface/chatflow.interface";

export abstract class Chatflow implements IChatflow {

  public jsonData!:any;
  public result: string = '';

  public container!: any;
  public subscription: Subscription;

  constructor(public liskovChatflow:LiskovChatflowModel) {
    this.subscription = new Subscription();
  }

  public getChatflowLayout(key:string) {
    return this.liskovChatflow.layoutManager.find(key);
  }
  
   /** Chatflow 실행에 필요한 요소 준비 */
   init(data?: any) {
    this.jsonData = data;
  }

  /** 
   * Chatflow 시작점
   *  scenario() : logic의 시나리오를 태우는 함수
   *  create(): layout의 시나리오를 태우는 함수
  */
  scenario(data?: any): void {
    this.liskovChatflow.scenarios.forEach((d:any) => {
      if (d.key === data) {
        if (d.value == null) return;
        if (d.value.first() == null) return;
        this.liskovChatflow.logicManager.find(d.key)?.prepare(d.value);
      }
    });

    // this.liskovChatflow.logicManager
    //         .find(this.liskovChatflow.service.logicService.getLogicKey(data))
    //         ?.scenario(this.liskovChatflow.service.logicService.getLogicValues(data));
  }
  create(data?: any) {
    this.getChatflowLayout('beforehand')?.create();
  }

  computed(data?:any): any {
    console.log('computed');
  }
  

  /** Chatflow 실헹 
   * prepare(): 실행 시, 준비 함수
   * 
   * draw(): Chatflow layout을 실헹하는 함수
   * events(): Chatflow logic을 실행하는 함수
  */
  prepare(data?: any): void {
    console.log('prepare');
  }
  finish(data: string): void {
    console.log('finish');
  }

  draw(index: number, container: any, data?: any): void {
    console.log('draw ownData', this.jsonData, 'data ', data);
  }
  async events(index: number, data?: any) {
    console.log('events index', index, 'data ', data);
  }

  
  /** 구독 해제 */
  clear(data?:data): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = new Subscription();
    }
  }
  
}