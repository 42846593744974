import { Observable } from "rxjs";
import { ICategoryCreateRequest, ICategoryCreateResponse, ICategoryDeleteRequest, ICategoryDeleteResponse, ICategoryGetRequest, ICategoryGetResponse, ICategoryMoveRequest, ICategoryMoveResponse, ICategoryNameChangeRequest, ICategoryNameChangeResponse, ICategoryPersonalCreate } from "../../pages/category-manage/interfaces/category/category.dto";
import { HttpService } from "../services/http.service";
import { inject } from "@angular/core";

export interface ICategoryRest{
    move(request: ICategoryMoveRequest): Observable<ICategoryMoveResponse>;
    get(request: ICategoryGetRequest): Observable<ICategoryGetResponse>;
    create(request: ICategoryCreateRequest): Observable<ICategoryCreateResponse>;
    delete(request: ICategoryDeleteRequest): Observable<ICategoryDeleteResponse>;
    rename(request: ICategoryNameChangeRequest): Observable<ICategoryNameChangeResponse>;
    createPersonal(request: ICategoryPersonalCreate): Observable<ICategoryCreateResponse>;
}

export class CategoryManageHttp implements ICategoryRest{
    private readonly httpService = inject(HttpService);

    move(request: ICategoryMoveRequest): Observable<ICategoryMoveResponse> {
        console.log('move request', request);
        return this.httpService.Patch('/category/move', request) as Observable<ICategoryMoveResponse>;
    }

    get(request: ICategoryGetRequest): Observable<ICategoryGetResponse> {
        //params는 queryString이다.
        return this.httpService.Get(`/category/${request.type}/${request.id}`) as Observable<ICategoryGetResponse>;
    }

    create(request: ICategoryCreateRequest): Observable<ICategoryCreateResponse> {
        return this.httpService.Post('/category', request) as Observable<ICategoryCreateResponse>;
    }

    delete(request: ICategoryDeleteRequest): Observable<ICategoryDeleteResponse> {
        return this.httpService.DeleteWithBody('/category', request) as Observable<ICategoryDeleteResponse>;
    }

    rename(request: ICategoryNameChangeRequest): Observable<ICategoryNameChangeResponse> {
        return this.httpService.Patch('/category/rename', request) as Observable<ICategoryNameChangeResponse>;
    }

    createPersonal(request: ICategoryPersonalCreate): Observable<ICategoryCreateResponse> {
        return this.httpService.Post('/category/personal', request) as Observable<ICategoryCreateResponse>;
    }

}